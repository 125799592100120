import { StaticImage } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import NavigationBar from "../components/navigation_bar"
import PlanInterface from "../interfaces/plan"
import { getPlans } from "../utils/fire"
import "./permit_plans.scss"
import { navigate } from "gatsby"
import { useGlobalState } from "../contexts/global_state"
import SEO from '../components/seo';

export default function PermitPlans() {
  const [plans, setPlans] = useState<PlanInterface[]>([])
  const [imagesLoaded, setImagesLoaded] = useState(0)
  const [, dispatch] = useGlobalState()

  function addToCart(plan: PlanInterface) {
    dispatch({ type: "SET_PERMIT_PLAN", payload: plan })
    navigate("/payment_form")
  }
  useEffect(() => {
    dispatch({ type: "SET_PERMIT_PLAN", payload: null })
    getPlans().then(data => {
      let permitPlans: PlanInterface[] = []
      data.docs.forEach((doc: any) => {
        const docData=doc.data();
        if (docData.category === "permitPlan"&&!docData.isHidden) permitPlans.push(docData)
      })
      permitPlans.sort((a,b)=>a.priority-b.priority)
      console.log(permitPlans)
      setPlans(permitPlans)
    }).catch(e=>console.log(e))
  }, [])
  return (
    <div>
      <SEO title="Permit Plans"/>
      <div className="top-section-permit-plans">
        <NavigationBar current="/permit-plans" />
        <div className="d-flex flex-wrap  align-items-center justify-content-around">
          <div className=" col-12 col-md-9 col-lg-5">
            <div className="solarEnergySolutions p-5 ">
              <div className="heading text-center text-lg-left">
                Complete Solar Energy Permit Plan Set
              </div>
              <div className="text">
                We will prepare the permit plan for your solar installation to
                ensure city codes and NEC regulations are met to ensure approval
                with your first visit to the city building and safety
                department. We also provide any corrections and updates of the
                submitted solar installation plans if needed, at no charge to
                keep solar installation costs down
              </div>
            </div>
          </div>

          <div className="d-flex mt-2 mx-1 px-2">
            <div className="d-flex flex-column">
              <StaticImage
                src="../images/permitPlans/Rectangle 110.png"
                className="m-1 m-md-2"
                alt="A dinosaur"
                placeholder="blurred"
              />

              <StaticImage
                src="../images/permitPlans/Rectangle 111.png"
                className="m-1 m-md-2 "
                alt="A dinosaur"
                placeholder="blurred"
              />
            </div>
            <div className="d-flex flex-column ">
              <StaticImage
                src="../images/permitPlans/Rectangle 112.png"
                className="m-1 m-md-2"
                alt="A dinosaur"
                placeholder="blurred"
              />
              <StaticImage
                src="../images/permitPlans/Rectangle 113.png"
                className="m-1 m-md-2"
                alt="A dinosaur"
                placeholder="blurred"
              />
            </div>
            <div className="d-flex flex-column ">
              <StaticImage
                src="../images/permitPlans/Rectangle 114.png"
                className="m-1 m-md-2"
                alt="A dinosaur"
                placeholder="blurred"
              />
              <StaticImage
                src="../images/permitPlans/Rectangle 115.png"
                className="m-1 m-md-2 "
                alt="A dinosaur"
                placeholder="blurred"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="services my-5">
        <div className="heading text-center">Our Services</div>
        <div className="text text-center">Take a look at services we offer</div>
        <div className="item-list mt-4 pt-3 flex-wrap d-flex justify-content-around">
          {(imagesLoaded !== plans.length || plans.length === 0) && (
            <div className="d-flex flex-column align-items-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <div className="m-2">Loading Plans</div>
          </div>
          )}
          {plans.map(plan => (
            <div
              key={plan.name}
              className={
                "plan-item mb-4 mb-lg-0  flex-column " +
                (imagesLoaded !== plans.length ? "d-none" : "d-flex")
              }
            >
              <img
                onLoad={() => setImagesLoaded(old => old + 1)}
                className="m-2"
                src={plan.imageURL}
                alt={plan.name}
              />
              <div className="p-3 flex-grow-1">
                <div className="plan-heading text-center">{plan.name}</div>
                <div className="plan-price text-center">${plan.price}</div>
                <hr />
                <div className="includes-in-this-package">
                  Included in this package:
                </div>
                <div className="includes-list flex-grow-1">
                  {plan.included.map(include => (
                    <div key={include} className="included-item my-2">
                      <StaticImage
                        className="mr-2"
                        alt="tick"
                        placeholder="blurred"
                        src="../images/permitPlans/round_tick.png"
                      />
                      {include}
                    </div>
                  ))}
                </div>
              </div>

              <Button
                onClick={() => addToCart(plan)}
                className="btn-block order-now"
              >
                Order Now
              </Button>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
